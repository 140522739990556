import { ClockIcon } from 'lucide-react';

import styles from './csam-policy.module.scss';

const CsamPolicyPage = () => (
    <main id={styles.csam}>
        <h2 className={styles.header}>Child Safety and CSAM Policy</h2>
        <label className={styles.lastUpdate}>
            <ClockIcon size={12} />
            Last Updated: February 17th, 2025
        </label>
        <p>
            Cappacho Corp ("Cappacho, " "we, " or "our") is committed to
            maintaining a safe and secure digital environment, free from Child
            Sexual Abuse Material (CSAM) and exploitation. This policy outlines
            our strict child protection measures, how we prevent, detect, and
            report CSAM, and the legal compliance required under Google’s
            standards and international laws.
        </p>
        <p>
            <h5>1. Published Standards</h5>
            Cappacho explicitly prohibits any form of Child Sexual Abuse and
            Exploitation (CSAE) in all publicly accessible policies, including:
            <br />
            <br />
            Terms of Service
            <br />
            <br />
            Community Guidelines
            <br />
            <br />
            This Child Safety Policy
            <br />
            <br />
            We maintain a zero-tolerance approach to CSAM, grooming, and child
            exploitation.
        </p>
        <p>
            <h5>2. In-App Mechanism for User Feedback</h5>
            To ensure our users can report any concerns directly within the app,
            Cappacho provides:
            <br />
            <br />A "Report Abuse" button on user profiles, messages, and
            content uploads
            <br />
            <br />A dedicated "Child Safety Concern" form in the app settings{' '}
            <br />
            <br />
            An email support system (support@cappacho.com) for direct reporting
            <br />
            <br />
            Users can report any suspected CSAM or child exploitation, which
            will be immediately reviewed by our moderation team.
        </p>
        <p>
            <h5>3. Addressing CSAM: Detection, Removal, and Response</h5>
            Cappacho actively detects, removes, and reports CSAM in compliance
            with Google and global regulations. Our process includes:
            <br />
            <br />
            Automated and Manual Detection.
            <br />
            <br />
            Human Moderation Team: Our team reviews flagged content to ensure
            accurate enforcement.
            <br />
            <br />
            Immediate Content Removal & Investigation.
            <br />
            <br />
            Any confirmed CSAM content is permanently removed.
            <br />
            <br />
            User accounts posting or engaging in CSAM-related behavior are
            banned.
            <br />
            <br />
            We collect evidence and report to legal authorities as per
            compliance laws.
        </p>
        <p>
            <h5>4. Compliance with Child Safety Laws</h5>
            Cappacho adheres to all global child safety laws and regulations,
            including but not limited to:
            <br />
            <br />
            U.S. PROTECT Act & EARN IT Act
            <br />
            <br />
            Children’s Online Privacy Protection Act (COPPA)
            <br />
            <br />
            EU General Data Protection Regulation (GDPR)
            <br />
            <br />
            National Center for Missing & Exploited Children (NCMEC) reporting
            requirements
            <br />
            <br />
            We immediately report confirmed CSAM to:
            <br />
            National Center for Missing and Exploited Children (NCMEC)
            <br />
            Internet Watch Foundation (IWF)
            <br />
            Local law enforcement agencies
            <br />
            <br />
            All reports are handled in accordance with legal requirements.
        </p>
        <p>
            <h5>5. Child Safety Point of Contact</h5>
            Cappacho designates a Child Safety Representative to receive
            notifications from Google Play and relevant authorities about CSAM
            content found in our app.
            <br />
            <br />
            Child Safety Contact: support@cappacho.com
            <br />
            <br />
            This representative is responsible for:
            <br />
            Receiving and reviewing CSAM-related notifications
            <br />
            Liaising with law enforcement and child safety organizations
            <br />
            Overseeing content moderation enforcement and compliance
        </p>
        <p>
            <h5>6. User Responsibilities</h5>
            All users must:
            <br />
            <br />
            Follow child protection laws and this policy
            <br />
            Report any suspected CSAM or exploitation immediately
            <br />
            Never share or engage with inappropriate content involving minors
            <br />
            Use Cappacho responsibly and ethically
            <br />
            <br />
            Failure to comply may result in account suspension, legal action,
            and permanent bans.
        </p>
        <p>
            <h5>7. Data Privacy & Secure CSAM Reporting</h5>
            CSAM reports are handled confidentially and securely.
            <br />
            We do NOT store CSAM content—flagged material is immediately removed
            and reported.
            <br />
            All handling of CSAM follows strict data protection laws and best
            practices.
        </p>
        <p>
            <h5>8. Policy Enforcement & Updates</h5>
            This policy is strictly enforced. Users violating it may face:
            <br />
            Immediate removal of content
            <br />
            Permanent account suspension
            <br />
            Legal prosecution under applicable laws
            <br />
            <br />
            We may update this policy periodically to enhance our child safety
            measures. Any significant changes will be communicated via:
            <br />
            In-app notifications
            <br />
            Email updates
        </p>
        <p>
            <h5>9. Contact Us</h5>
            For any questions, reports, or concerns regarding child safety,
            please contact:
            <br />
            <br />
            <b>support@cappacho.com</b>
            <br />
            <br />
            Cappacho is committed to creating a secure platform that prioritizes
            child safety, legal compliance, and proactive CSAM prevention.
        </p>
    </main>
);

export default CsamPolicyPage;
