import styles from './account-deactivation.module.scss';
import { ClockIcon } from 'lucide-react';

const AccountDeactivationPage = () => (
    <main id={styles.accountDeactivation}>
        <h2 className={styles.header}>Deactivate your account</h2>
        <label className={styles.lastUpdate}>
            <ClockIcon size={12} />
            Last Updated: February 8th, 2025
        </label>
        <p>
            <h5>About deactivating your Cappacho account</h5>
            You can delete your account at Cappacho from Cappacho app.
            <br />
            <br />
            Once deleted, you will not be able to reactivate your existing
            account. You will need to create a new Cappacho account should you
            decide to begin using Cappacho again.
            <br />
            <br />
            When you are ready to delete your account, proceed with the
            following steps:
            <ul>
                <li>
                    Open Cappacho mobile application and sign in to your
                    account.
                </li>
                <li>
                    <b>Go to your profile</b>&nbsp;{'>'}&nbsp;<b>Settings</b>
                    &nbsp;{'>'}&nbsp;
                    <b>Delete account</b>.
                </li>
                <li>
                    Select your reason for deletion and press:&nbsp;
                    <b>Confirm</b>
                </li>
            </ul>
        </p>
        <p>
            <h5>Contact Us</h5>
            If you have questions or concerns about this Account Deactivation
            Policy, please contact us at:
            <br />
            <br /> Email:&nbsp;
            <a href="mailto:support@cappacho.com?subject=Account Deactivation - Questions">
                support@cappacho.com
            </a>
            <br />
            <br />
            Thank you for using Cappacho. We are committed to providing you with
            a secure and enjoyable experience.
        </p>
    </main>
);

export default AccountDeactivationPage;
