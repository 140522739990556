import styles from './terms-and-conditions.module.scss';
import { ClockIcon } from 'lucide-react';

const TermsAndConditionsPage = () => (
    <main id={styles.termsAndConditions}>
        <h2 className={styles.header}>Terms and Conditions</h2>
        <label className={styles.lastUpdate}>
            <ClockIcon size={12} />
            Last Updated: February 8th, 2025
        </label>
        <p>
            <h5>1. Introduction</h5>
            Welcome to Cappacho ("we," "our," or "us"). These Terms and
            Conditions ("Terms") govern your use of our mobile application ("the
            App") and any related services we provide. By accessing or using
            Cappacho, you agree to comply with these Terms. If you do not agree,
            please do not use the App.
        </p>
        <p>
            <h5>2. Eligibility</h5>
            To use the App, you must:
            <ul>
                <li>
                    Be on the minimum age of digital consent in your country.
                </li>
                <li>
                    Provide accurate and complete information during
                    registration.
                </li>
                <li>
                    Agree to these Terms and any applicable laws in your
                    jurisdiction.
                </li>
            </ul>
        </p>
        <p>
            <h5>3. User Responsibilities</h5>
            By using Cappacho, you agree to:
            <ul>
                <li>
                    Use the App only for lawful purposes and in accordance with
                    these Terms.
                </li>
                <li>
                    Refrain from uploading or sharing content that is harmful,
                    offensive, or infringes on others' rights.
                </li>
                <li>
                    Maintain the confidentiality of your login credentials and
                    notify us immediately if you suspect unauthorized access.
                </li>
            </ul>
        </p>
        <p>
            <h5>4. Prohibited Activities</h5>
            When using the App, you must not:
            <ul>
                <li>Attempt to reverse engineer, modify, or hack the App.</li>
                <li>
                    Use automated tools such as bots or scrapers to access our
                    services.
                </li>
                <li>
                    Exploit the App for commercial purposes without our prior
                    written consent.
                </li>
                <li>
                    Violate the intellectual property rights of Cappacho or
                    others.
                </li>
            </ul>
        </p>
        <p>
            <h5>5. Intellectual Property</h5>
            All content, designs, trademarks, and features within Cappacho are
            the exclusive property of Cappacho Corp or its licensors. You may
            not reproduce, distribute, or create derivative works without our
            express written permission.
        </p>
        <p>
            <h5>6. Data Collection and Privacy</h5>
            Your use of Cappacho is governed by our Privacy Policy, which
            outlines how we collect, use, and protect your data. By using the
            App, you acknowledge and agree to the practices described in our
            Privacy Policy.
        </p>
        <p>
            <h5>7. Cookies</h5>
            For information about how we use cookies, please refer to our
            Cookies Policy. By using the App, you consent to our use of cookies
            as outlined in the policy.
        </p>
        <p>
            <h5>8. Termination</h5>
            We reserve the right to suspend or terminate your account at our
            sole discretion if you violate these Terms, engage in fraudulent
            activity, or act in a manner that disrupts the App’s functionality.
        </p>
        <p>
            <h5>9. Limitation of Liability</h5>
            To the maximum extent permitted by law:
            <ul>
                <li>
                    Cappacho is provided “as is” without warranties of any kind.
                </li>
                <li>
                    We are not liable for any indirect, incidental, or
                    consequential damages arising from your use of the App.
                </li>
                <li>
                    We do not guarantee uninterrupted or error-free operation of
                    the App.
                </li>
            </ul>
        </p>
        <p>
            <h5>10. Modifications</h5>
            We may revise these Terms from time to time to reflect updates to
            our practices, changes in legal requirements, or enhancements to our
            services. We will notify you of significant changes via the App or
            email. Continued use of the App after changes constitutes your
            acceptance of the updated Terms.
        </p>
        <p>
            <h5>11. Governing Law</h5>
            These Terms are governed by the laws of [Your Jurisdiction]. Any
            disputes arising from these Terms will be resolved in the courts of
            [Your Jurisdiction].
        </p>
        <p>
            <h5>12. Contact Us</h5>
            If you have any questions about these Terms, please contact us at:
            <br />
            <br />
            Email:&nbsp;
            <a href="mailto:support@cappacho.com?subject=T&A - Questions">
                support@cappacho.com
            </a>
            <br />
            <br />
            Thank you for using Cappacho. We are committed to providing you with
            a secure and enjoyable experience.
        </p>
    </main>
);

export default TermsAndConditionsPage;
