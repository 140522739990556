import styles from './not-found.module.scss';
import Button from '../../components/button/button';

const NotFoundPage = () => (
    <main>
        <h3 className={styles.title}>
            Ooops... <br />
            Well, this is embarrassing
        </h3>
        <div className={styles.notFoundContainer}>
            <h1 className={styles.notFound}>404</h1>
            <span>This page does not exist (for now)</span>
        </div>
        <div className={styles.buttonContainer}>
            <Button>Back to home</Button>
        </div>
    </main>
);

export default NotFoundPage;
