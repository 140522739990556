import { ClockIcon } from 'lucide-react';

import styles from './privacy-policy.module.scss';

const PrivacyPolicyPage = () => (
    <main id={styles.privacyPolicy}>
        <h2 className={styles.header}>Privacy Policy</h2>
        <label className={styles.lastUpdate}>
            <ClockIcon size={12} />
            Last Updated: February 8th, 2025
        </label>
        <p>
            Cappacho Corp ("Cappacho," "we," or "our") is committed to
            protecting the privacy of its users. This Privacy Policy outlines
            how we collect, use, store, and protect your personal information
            when using our mobile application ("the App"). By using Cappacho,
            you agree to the terms of this Privacy Policy.
        </p>
        <p>
            <h5>1. Information We Collect When Registering</h5>
            For Cappacho, we collect the following personal information: Full
            name Email address Age We do not collect payment information or
            sensitive data such as precise location.
        </p>
        <p>
            <h5>2. How We Use Your Information</h5>
            The information collected is used for the following purposes: To
            generate generic metrics and statistics about App usage. To identify
            user demographic trends for potential investors. In the future, to
            personalize the content displayed in each user's "feed."
        </p>
        <p>
            <h5>3. Use of Third-Party Tools</h5>
            We use the following third-party tools to enhance user experience
            and collect metrics: Firebase: for user management and data storage.
            Google Analytics: to generate usage reports and analysis. The data
            collected through these tools is not shared with external third
            parties.
        </p>
        <p>
            <h5>4. Data Storage</h5>
            Your data is stored on secure servers hosted by DigitalOcean. We
            retain your data for the lifetime of your account. If you choose to
            delete your account: All your data will be encrypted and will remain
            inaccessible for any use other than statistical and generic
            purposes.
        </p>
        <p>
            <h5>5. Data Security</h5>
            We take reasonable measures to protect your personal information:
            Data is transmitted using HTTPS protocol. Passwords and other
            sensitive data are stored encrypted in our database. Our servers are
            isolated from unauthorized public connections. However, no data
            transmission over the Internet or storage system is completely
            secure. While we strive to protect your information, we cannot
            guarantee its absolute security.
        </p>
        <p>
            <h5>6. User Rights</h5>
            You have the right to: Access the personal information we hold about
            you. Request correction of any inaccurate data. Request deletion of
            your account and personal data. To exercise these rights, please
            contact us at&nbsp;
            <a href="mailto:support@cappacho.com?subject=Request Personal Information">
                support@cappacho.com
            </a>
            .
        </p>
        <p>
            <h5>7. Changes to This Privacy Policy</h5>
            We may update this Privacy Policy periodically to reflect changes in
            our practices or for other operational, legal, or regulatory
            reasons. We will notify you of significant changes via the App or
            email.
        </p>
        <p>
            <h5>Contact</h5>
            If you have any questions or concerns about this Privacy Policy, you
            can contact us at:
            <br />
            <br />
            Email:&nbsp;
            <a
                href="mailto:support@cappacho.com?subject=Privacy Policy - Questions"
                target="_blank"
            >
                support@cappacho.com
            </a>
            <br />
            <br />
            Thank you for using Cappacho. We are committed to providing you with
            a secure and enjoyable experience.
        </p>
    </main>
);

export default PrivacyPolicyPage;
