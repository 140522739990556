import { Route, Routes } from 'react-router';

import styles from './app.module.scss';
import MainNav from './components/main-nav/main-nav';
import Footer from './components/footer/footer';
import { Pages } from './pages/pages';
import NotFoundPage from './pages/not-found/not-found.page';

function App() {
    return (
        <main id={styles.app}>
            <MainNav />
            <Routes>
                {Pages.map((page) => (
                    <Route
                        key={page.id}
                        path={page.path}
                        element={page.component}
                    />
                ))}
                <Route path={'*'} element={<NotFoundPage />} />
            </Routes>
            <Footer />
        </main>
    );
}

export default App;
