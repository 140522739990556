import styles from './eula.module.scss';
import { ClockIcon } from 'lucide-react';

const EulaPage = () => (
    <main id={styles.eula}>
        <h2 className={styles.header}>End User License Agreement (EULA)</h2>
        <label className={styles.lastUpdate}>
            <ClockIcon size={12} />
            Last Updated: February 8th, 2025
        </label>
        <p>
            Welcome to Cappacho ("we," "our," or "us"). This End User License
            Agreement ("EULA") governs your use of our software application
            ("the Software") and any related services we provide. By
            downloading, installing, or using the Software, you agree to comply
            with this EULA. If you do not agree to these terms, please do not
            use the Software.
        </p>
        <p>
            <h5>1. Introduction</h5>
            This End User License Agreement (“Agreement”) is a legal agreement
            between you (“User”) and Cappacho (“Company”) governing your access
            to and use of the Cappacho mobile application (“App”). By accessing
            or using the App, you agree to be bound by this Agreement. If you do
            not agree to the terms, you must not use the App.
        </p>
        <p>
            <h5>2. User-Generated Content</h5>
            <h6>2.1 No Tolerance for Objectionable Content</h6>
            Cappacho enforces a zero-tolerance policy for objectionable content.
            Users must not post, upload, or share any content that:
            <ul>
                <li>Contains hate speech, harassment, or threats.</li>
                <li>Promotes violence or illegal activities.</li>
                <li>Is sexually explicit or obscene.</li>
                <li>Violates any applicable laws or regulations.</li>
                <li>Infringes on intellectual property rights.</li>
                <li>Is deemed inappropriate by Cappacho moderators.</li>
            </ul>
            <h6>2.2 User Responsibility</h6>
            By using the App, you agree to:
            <ul>
                <li>Abide by all terms outlined in this Agreement.</li>
                <li>
                    Not post, share, or engage in objectionable or abusive
                    behavior.
                </li>
                <li>
                    Ensure that any content you submit complies with Cappacho’s
                    standards and community guidelines.
                </li>
            </ul>
        </p>
        <p>
            <h5>3. Content Moderation and Reporting</h5>
            <h6>3.1 Content Filtering</h6>
            Cappacho employs automated and manual moderation techniques to
            identify and remove objectionable content promptly. Users are
            advised that no system is foolproof, and user reports are critical
            to maintaining a safe environment.
            <h6>3.2 Reporting Mechanism</h6>
            Users can report objectionable content directly through the App
            using the “Report” button available on each post. Reported content
            will be reviewed by Cappacho moderators within 24 hours.
            <h6>3.3 Blocking Abusive Users</h6>
            Users have the ability to block other users directly within the App.
            This action will prevent further interaction or visibility of the
            blocked user’s content.
            <h6>3.4 Developer Action</h6>
            Cappacho reserves the right to take the following actions against
            users who violate this Agreement:
            <ul>
                <li>Immediate removal of objectionable content.</li>
                <li>
                    Temporary or permanent suspension of the user’s account.
                </li>
                <li>
                    Reporting of illegal activities to relevant authorities.
                </li>
            </ul>
        </p>
        <p>
            <h5>4. Prohibited Activities</h5>
            Users are prohibited from:
            <ul>
                <li>
                    Attempting to hack or otherwise disrupt the App’s
                    operations.
                </li>
                <li>Using the App for any unlawful or fraudulent purposes.</li>
                <li>
                    Circumventing or disabling security-related features of the
                    App.
                </li>
                <li>
                    Posting spam, advertisements, or unauthorized promotional
                    material.
                </li>
            </ul>
        </p>
        <p>
            <h5>5. Termination</h5>
            Cappacho reserves the right to terminate your access to the App
            without notice if you violate any terms of this Agreement. Upon
            termination, you must cease all use of the App and delete any copies
            of it from your devices.
        </p>
        <p>
            <h5>6. Liability Disclaimer</h5>
            Cappacho is not responsible for any user-generated content and
            disclaims liability for any harm resulting from interactions between
            users. Users are encouraged to report issues promptly to enable
            swift resolution.
        </p>
        <p>
            <h5>7. Amendments</h5>
            Cappacho reserves the right to modify this Agreement at any time.
            Users will be notified of significant changes, and continued use of
            the App constitutes acceptance of the revised terms.
        </p>
        <p>
            <h5>8. Contact Information</h5>
            For questions or concerns about this Agreement, please contact
            Cappacho at
            <br />
            <br />
            Email:&nbsp;
            <a href="mailto:support@cappacho.com?subject=EULA - Questions">
                support@cappacho.com
            </a>
        </p>
        <p>
            <h5>9. Governing Law</h5>
            This Agreement shall be governed by and construed in accordance with
            the laws of [Your Jurisdiction], without regard to its conflict of
            law provisions. By agreeing to this EULA, you acknowledge that you
            have read, understood, and agreed to the terms outlined above.
            <br />
            <br />
            Thank you for using Cappacho. We are committed to providing you with
            a secure and enjoyable experience.
        </p>
    </main>
);

export default EulaPage;
