import { ReactNode } from 'react';

import HomePage from './home/home.page';
import PrivacyPolicyPage from './privacy-policy/privacy-policy.page';
import CookiesPolicyPage from './cookies-policy/cookies-policy.page';
import EulaPage from './eula/eula.page';
import TermsAndConditionsPage from './terms-and-conditions/terms-and-conditions.page';
import AccountDeactivationPage from './account-deactivation/account-deactivation.page';
import CsamPolicyPage from './csam-policy/csam-policy.page';

type Page = {
    id: string;
    path: string;
    component: ReactNode;
};

const Pages: Page[] = [
    {
        id: 'e5071444-d1f4-4736-b389-93931aed5f89',
        path: '/',
        component: <HomePage />,
    },
    {
        id: '544e7456-5f86-475f-9887-688408e5105a',
        path: '/privacy-policy',
        component: <PrivacyPolicyPage />,
    },
    {
        id: '0d7ae1cd-2b49-4954-8171-7046c36b4c30',
        path: '/cookies-policy',
        component: <CookiesPolicyPage />,
    },
    {
        id: '6f9f893e-706a-45f4-bf8d-3c70c61852cf',
        path: '/eula',
        component: <EulaPage />,
    },
    {
        id: '30bfd7bf-c1ae-4d10-a8e4-ad4d24e19479',
        path: '/terms-and-conditions',
        component: <TermsAndConditionsPage />,
    },
    {
        id: 'bd1973f7-874f-456a-adae-c559ef5545e3',
        path: '/account-deactivation',
        component: <AccountDeactivationPage />,
    },
    {
        id: '394b3078-47a5-49f0-bba4-315a64cb1ac7',
        path: '/csam-policy',
        component: <CsamPolicyPage />,
    },
];

export { Pages };
